<template>
<div>
  <v-container fluid>
    <v-layout column align-center>
      <h1>Welcome to D-VIN</h1>
    </v-layout>
  </v-container>
</div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>

</style>
